/* App.css */
:root {
  --primary-color: #1600a8;
  --secondary-color: #2d439b;
  --font-size: 16px;
}

/* Basic styling elements */

.centered {
  text-align: -webkit-center;
}

.title {
  color: var(--primary-color);
  margin-bottom: 20px;
  margin-top: 0px;
}

.description {
  font-size: 18px;
  color: #555;
}

.main-content {
  padding-top: 100px;
  background-color: #f8f8f8;
}

/* Navigation */

.navigation {
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Fix the navbar at the top */
  right: 0; /* Align to the right */
  left: 0; /* Align to the left */
  top: 0; /* Align to the top */
  width: 100vw;
  z-index: 1000; /* Ensure it's above other content */
  box-shadow: none; /* No shadow initially */
  transition: box-shadow 0.3s; /* Smooth transition for the shadow */
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}


.navigation {
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Fix the navbar at the top */
  right: 0; /* Align to the right */
  left: 0; /* Align to the left */
  top: 0; /* Align to the top */
  width: 100vw;
  z-index: 1000; /* Ensure it's above other content */
  box-shadow: none; /* No shadow initially */
  transition: box-shadow 0.3s; /* Smooth transition for the shadow */
  overflow-x: auto; /* Enable horizontal scrolling */
  -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
  white-space: nowrap; /* Prevent wrapping of nav links */
}


.navigation.scrolled {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow appears on scroll */
}

.nav-link, .nav-logo {
  margin: 0 15px; /* Adjust spacing around links and logo */
}

.nav-link {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s;
  width: 135px;
}

.nav-link:hover {
  color: var(--secondary-color); /* Lighter shade of green on hover */
}

.nav-logo {
  flex-grow: 1; /* Allows the logo to take up available space, centering it */
  text-align: center;
  width: 90px;
  vertical-align: middle;
}

/* Reset flex-grow for links to keep them at their natural width */
.navigation > * {
  flex-grow: 0;
}

@media (max-width: 768px) {
  .navigation {
    justify-content: flex-start; /* Align content to start */
  }

  .navigation .nav-link.show-on-mobile {
    display: inline-block; /* Show only specific links */
  }
}

/* Home page */

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding-top: 100px;
}

ul {
  list-style-type: none; /* Remove list markers (bullets) */
  margin-left: -50px;
}

.input-page {
  width: 100vw;
  height: 100vh;
  text-align: -webkit-center;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 75vw;
}

.input-title {
  margin-right: 10px;
  font-weight: bold;
  width: 20%;
}

.input-field {
  width: 80%; /* Adjust width as necessary */
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  width: 20%;
  padding: 15px;
  margin-top: 20px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--secondary-color);
  color: white;
}

.submit-button:hover {
  background-color: var(--primary-color);
}


.progress-bar {
  background-color: #ccc;
  height: 20px;
  width: 80%;
  text-align: left;
}

.loading-progress {
  background-color: var(--primary-color);
  height: 100%;
  width: 0; /* Start with 0% width */
  transition: width 0.3s ease; /* Smooth transition effect */
}



.website-iframe {
  width: 100%;
  height: 100vh;
  border: none;
}




@media (max-width: 768px) {


  .submit-button {
    width: 50%;
  }

  /* .navigation .nav-link {
    display: none;
  } */

  .navigation {
    right: auto;
  }

  .navigation .nav-link.show-on-mobile {
      display: inline-block; /* Show only specific links */
  }

  .hide-mobile {
    display: none
  }
}

/* Footer */
.footer {
  background-color: white;
  text-align: center;
  padding: 20px;
  font-size: 14px;
  margin-top: 50px;
}

.social-links {
  margin-bottom: 10px;
}

.social-links a {
  margin: 0 10px;
}

.social-links img {
  width: 24px; /* Adjust as needed */
  height: auto;
}

@media (max-width: 768px) {
  .footer {
    font-size: 12px;
  }

  .social-links img {
    width: 20px; /* Smaller size for mobile */
  }
}



/* About page */

.about-title {
  color: #000; /* Black color for the title */
  margin-bottom: 20px;
  font-size: 24px; /* Smaller size */
  font-family: 'Arial', sans-serif; /* You can choose a font that suits your design */
  font-weight: 100;
}

.about-page {
  color: #333;
  text-align: left;
}

.text-box {
  background-color: #f8f8f8; /* Light grey background */
  padding: 20px;
}

.info-box {
  display: flex;
  background-color: white;
}

.highlight-box {
  background-color: #f8f8f8; /* Light grey background */
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.info-image {
  width: 40%; /* Adjust size as needed */
  margin-right: 20px;
}

.info-text {
  flex: 1;
  font-size: 18px; /* Larger font size */
  text-align: left; /* Center the text */
  margin: auto; /* Center the content in the flex container */
  font-weight: 600;
}

@media (max-width: 768px) {
  .info-box {
      flex-direction: column;
  }

  .info-image {
      width: 100%; /* Adjust as needed */
      margin: 0 auto 20px; /* Center the image and add space below */
  }

  .info-text {
      text-align: center; 
      padding: 10px;/* Optional: Center text on smaller screens */
  }

  .collaboration-text {
    padding: 0 10px;
  }
  /* ... other responsive styles ... */
}


.collaboration-text {
  /* Additional styling for the text */
  text-align: center;
  font-size: 18px; /* Larger font size */
  font-weight: 500;
  padding-bottom: 10px;
}

.slide {
  flex: 0 0 auto;
  scroll-snap-align: start;
  text-align: center;
}

.slide img {
  max-width: 400px; /* Adjust as needed */
  height: auto;
  display: block;
  margin: auto;
}

.slider {
  display: flex;
  animation: slide 30s linear infinite; /* Adjust duration as needed */
  gap: 20px;
  align-items: center;
}

.slider::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

@keyframes slide {
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); } /* Moves the slider to the left */
}

@media (max-width: 768px) {
  .info-image {
    font-size: 12px;
  }
}


/* Contact page */


.contact-page .title {
  padding-top: 0px;
}

.contact-page {
  text-align: center;
  padding: 75px;
}

.contact-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 100px;
}

.contact-form input,
.contact-form textarea {
  width: 90%; /* Aanpassen naar wens */
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form button {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-form button:hover {
  background-color: var(--secondary-color);
}

.textarea {
  height: 150px
}

@media (max-width: 768px) {
  .contact-form {
    padding: 0 0;
  }

  .contact-page {
    padding: 10px;
  }
}


/* Waarom page */
.waarom-page .title {
  text-align: center;
}

.waarom-page {
  padding: 100px;
  padding-top: 75px;
}

.waarom-page .reason {
  margin-bottom: 20px;
  padding: 10px; /* Add padding */
  border-bottom: 1px solid #ddd; /* Underline each reason */
  background-color: white; /* Optional: set background color for contrast */
}

.waarom-page .reason-title {
  font-size: 20px;
  color: #000; /* Make text black */
}

.waarom-page .reason-description {
  font-size: 16px;
  color: #000; /* Make text black */
}
