/* VoorspellingenPage.css */
.voorspellingen-centered {
  text-align: center;
}

.voorspellingen-predictions-table-container {
  margin-top: 20px;
}

.voorspellingen-table-scroll {
  overflow-x: auto;
  max-width: 100%;
  margin: 25px;
}

.voorspellingen-table {
  width: 100%;
  border-collapse: collapse;
}

/* Set width and minimum width for regular cells */
.voorspellingen-td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  width: 100px; /* Adjust this value as needed */
  min-width: 150px; /* Adjust this value as needed */
}

/* Set width and minimum width for header cells */
.voorspellingen-th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  background-color: #f2f2f2;
  width: 150px; /* Adjust this value as needed */
  min-width: 200px; /* Adjust this value as needed */
}

.notification{
  padding-bottom: 25px;
  padding-top: 50px;
}