.page-title {
    text-align: center;
}

/* Registration page */
.registration-container {
    /* display: flex; */
    height: 75vh;
    background-image: url('../images/background.jpg'); /* Replace 'path/to/your/background.jpg' with the actual path to your background image */
    background-size: cover;
    text-align: center;
    padding-top: 5%;
}

.registration-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 25px;
}

.registration-form label {
    display: block;
    margin-bottom: 5px;
}

.registration-form input,
.registration-form textarea,
.registration-form select {
    width: 95%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.registration-form button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 0px;
}

/* Login Page */

/* Registration.css */

.login-container {
    /* display: flex; */
    height: 75vh;
    background-image: url('../images/background.jpg'); /* Replace 'path/to/your/background.jpg' with the actual path to your background image */
    background-size: cover;
    text-align: -webkit-center;
    padding-top: 7.5%;
}

.register-here {
    background-color: #007bff;
    width: 300px;
    padding: 5px;
    border-radius: 10px;
}

.register-here a {
    color: white;
    text-decoration: underline;
}

.registration-form {
    margin: auto;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Adjust the alpha value to control the background opacity */
    border-radius: 10px;
    width: 300px; /* Adjust the width of the form as needed */
}

.page-title {
    color: #fff; /* Set the color of the title to white or a suitable color for better visibility */
}

.login-container .same-line {
    color: #fff; /* Set the color of the text to white or a suitable color for better visibility */
    margin-top: 10px;
}

/* .login-pagina-tekst {
margin-left: 20px;
} */
  
  /* Add any other styles you may need */
  

.register-tekst {
    text-align: left;
    margin-left: 15px;
}