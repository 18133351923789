.app {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

p {
  color: #666;
  font-size: 16px;
  margin-bottom: 15px;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.points-info,
.toto-info,
.bonus-info {
  margin-top: 30px;
}

.points-info h2,
.toto-info h2,
.bonus-info h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.points-info ul {
  list-style-type: disc;
  /* margin-left: 20px; */
  list-style-type: none;
}

.app {
  text-align: center;
}

.points-info ul li {
  color: #666;
  font-size: 16px;
  margin-bottom: 10px;
}

.app li {
  color: #666;
  font-size: 16px;
  margin-bottom: 10px;
}

.app ol ul {
  padding: 0;
  margin: 0;
}
  