/* src/Nieuws.css */

.nieuws-page {
  padding: 20px;
  background: url('../images/news_background.jpg') no-repeat center center fixed;
  /* Replace with your image path */
  background-size: cover;
  /* Cover the entire viewport */
  min-height: 100vh;
  /* Ensure it covers the full viewport height */
}

.nieuws-page h1 {
  color: white;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  /* Optional: Add shadow for better readability */
}

.story {
  background-color: rgba(255, 255, 255, 0.9);
  /* Slightly transparent white */
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center
}

.story h2 {
  color: var(--secondary-color);
}

.story p {
  color: #333;
}

.nederlandselftal {
  width: 40vw;
}