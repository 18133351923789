.centered {
    text-align: center;
}

/* Styling for the general standings table */
.standings-table-container {
    margin: 20px;
    overflow: auto;
    /* Add overflow property to enable scrolling */
}

.standings-table {
    width: calc(100% - 150px);
    /* Adjust width to accommodate margin */
}

.standings-table th,
.standings-table td {
    padding: 10px;
    transition: background-color 0.3s;
    /* Add transition effect */
    vertical-align: middle; /* Align content vertically */
}

.standings-table th {
    background-color: #f2f2f2;
}

.standings-table tbody tr:hover {
    background-color: #f0f0f0;
    /* Add hover effect */
}

/* Styling for the my points table */
.user-predictions-table-container {
    margin: 20px;
    overflow: auto;
    /* Add overflow property to enable scrolling */
}

.user-predictions-table {
    width: calc(100% - 150px);
    margin-bottom: 50px;
    /* Adjust width to accommodate margin */
}

.user-predictions-table th,
.user-predictions-table td {
    padding: 10px;
    transition: background-color 0.3s;
    /* Add transition effect */
    vertical-align: middle; /* Align content vertically */
}

.user-predictions-table th {
    background-color: #f2f2f2;
}

.user-predictions-table tbody tr:hover {
    background-color: #f0f0f0;
    /* Add hover effect */
}

.cell {
    max-width: 30%;
    width: 30%;
}

.gold-row {
    background: linear-gradient(145deg, #ffd700, #ffec8b);
    color: #000;
    border: 2px solid #000000;
    font-weight: bold;
}

.silver-row {
    background: linear-gradient(145deg, #c0c0c0, #e0e0e0);
    color: #000;
    border: 2px solid #000000;
    font-weight: bold;
}

.bronze-row {
    background: linear-gradient(145deg, #cd7f32, #dda15e);
    color: #000;
    border: 2px solid #000000;
    font-weight: bold;
}

.positive-movement {
    color:#37e245;
    font-weight: bold;
    text-shadow: 
        -1px -1px 2px #fff,  
        1px -1px 2px #fff,
        -1px 1px 2px #fff,
        1px 1px 2px #fff;
}

.negative-movement {
    color: #c22727;
    font-weight: bold;
    text-shadow: 
    -1px -1px 2px #fff,  
    1px -1px 2px #fff,
    -1px 1px 2px #fff,
    1px 1px 2px #fff;
}

.last-row {
    border: 2px solid #000000;
    font-weight: bold;
}

.standings-table {
    width: 100%;
    border-collapse: collapse;
}

.standings-table th,
.standings-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd; /* Default border color */
    background-color: linear-gradient(145deg, #c3bcbc, #ffffff);
    vertical-align: middle; /* Align content vertically */
    height: 34px;
}

/* Apply black borders to the first five rows
.standings-table tbody tr:nth-child(-n+5) th,
.standings-table tbody tr:nth-child(-n+5) td {
    border: 1px solid #000000;
} */

/* tussenstand.css */
.flame-container {
    display: inline-block;
    position: relative;
    margin-left: 8px;
}

.flame-image {
    width: 30px;
    height: 30px;
}

.streak-number {
    position: absolute;
    top: -2px;
    left: -1px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 12px;
    font-weight: bold;
}

.space {
    display: flex;
    align-items: center; /* Align items vertically in the center */
}

/* Optional: Add some margin to the streak symbol for spacing */
.flame-container {
    margin-left: 8px; /* Adjust the spacing as needed */
}
