.home-page-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.home-page-container h1 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.home-page-container p {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 15px;
}

.home-page-container h2 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.home-page-container ol {
  text-align: left;
  margin-bottom: 20px;
}

.home-page-container ol li {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.6;
}

.home-page-container p:last-child {
  margin-top: 20px;
}

.ek-image {
  display: block;
  margin: 0 auto 20px;
  padding-top: 10px;
  /* Center the image and add some bottom margin */
  max-width: 100%;
  /* Ensure the image doesn't exceed its container's width */
}

.center {
  text-align: center;
  padding-bottom: 15px;
}
